import { createWebHistory, createRouter } from "vue-router";
import Home from "@/components/Home.vue";
import Viewer3DView from "@/components/Viewer3D.vue";
import ViewerCallTest from "@/components/ViewerCallTest.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/Viewer3D/:productKey?",
        name: "Viewer3D",
        component: Viewer3DView,
    },
    {
        path: "/ViewerCallTest",
        name: "ViewerCallTest",
        component: ViewerCallTest,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;