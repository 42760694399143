<template>
    <nav>
      <img src="@/assets/logo.png" id="img-logo" />
      <span class="title">3D-Viewer</span>
    </nav>
</template>

<style scoped>
nav {
  height: 64px;
  background-color: var(--color-main);
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  position: fixed;
  text-align: left;
}

img {
  max-height: 100%;
}

.title {
  color: white;
  padding: 20px;
  font-weight: bold;
  font-size: 2em;
  position: relative;
  top: 7.5px;
}
</style>

<style>
:target::before {
  content: "";
  display: block;
  height: 64px; /* fixed header height*/
  margin: -64px 0 0; /* negative fixed header height */
}
</style>

<script js>
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  //   components: { FontAwesomeIcon },
  name: "TopBar",
  data() {},
  methods: {},
  mounted() {},
};
</script>