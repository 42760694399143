<template>
  <div id="viewerwrapper">
    <canvas ref="viewerCanvas"></canvas>
    <viewer-3-d-overlay
      v-if="vwr != null"
      :Viewer3D="vwr"
      class="overlay"
    ></viewer-3-d-overlay>
    <div class="loadingContainer" v-if="vwr && vwr.isLoading()">
      <font-awesome-icon icon="spinner" spin />
    </div>
  </div>
</template>

<style scoped>
canvas {
  height: 100vh;
  width: 100%;
}

.overlay {
  position: absolute;
  z-index: 999;
  top: 0px;
  left: 0px;
}

.loadingContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  font-size: 6em;
  pointer-events: none;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
</style>

<style scoped>
#viewerwrapper {
  max-height: 100vh;
  max-width: 100%;
}
</style>

<script js>
import { Viewer3D } from "@/scripts/Viewer3D.ts";
import Viewer3DOverlay from "./Viewer3DOverlay.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Axios from "axios";

export default {
  components: { Viewer3DOverlay, FontAwesomeIcon },
  name: "Viewer3DView",
  data() {
    return {
      currentCount: 0,
      viewer: null,
      vwr: null,
      debug: false,
      opener: null,
    };
  },
  watch: {
    debug(oldValue, newValue) {
      console.log(oldValue, newValue);
    },
  },
  methods: {
    initializeViewer() {
      // let parms = {
      //   mtlUrl: "/api/GetTestMtlFile",
      //   objUrl: "/api/GetTestObjFile",
      // };
    },
    receiveMessage(msg) {
      if (msg.data.type == "LoadViewer") {
        
        var productsdata = new Array();
        if (msg.data.data.length == undefined) {
          productsdata.push(msg.data.data);
        }
        else
        {
            productsdata = msg.data.data;
        }

        this.vwr
          .initialize(productsdata)
          .then(() => {
            this.viewerInitialized(true);
          })
          .catch(() => {
            this.viewerInitialized(false);
          });
      } else if (msg.data.type == "LoadViewerRawRemote") {
        console.log(msg.data.data);
        this.vwr
          .initializeRawRemote(msg.data.data)
          .then(() => {
            this.viewerInitialized(true);
          })
          .catch(() => {
            this.viewerInitialized(false);
          });
      } else if (msg.data.type == "LoadViewerRaw") {
        try {
          this.vwr.initializeRaw(msg.data.data);
          this.viewerInitialized(true);
        } catch {
          this.viewerInitialized(false);
        }
      } else if (msg.data.type == "AppendError") {
        // todo append error to html
      }
    },
    createPostMsgObj(eventName, data) {
      return {
        type: "Viewer3D:" + eventName,
        data: data,
      };
    },
    viewerInitialized(succes) {
        if (window.parent || window.opener) {
        this.getOpener().postMessage(
          // success == true so we are 100% sure it is a boolean
          this.createPostMsgObj("ObjectLoaded", { success: succes == true }),
          "*"
        );
      }
    },
    getOpener() {
      if (window.opener) {
        return window.opener;
      }

      return window.parent;
    },
  },
  mounted() {
    document.querySelector("body").classList.add("no-overflow");
    var that = this;
    this.vwr = new Viewer3D(this.$refs.viewerCanvas, window.localStorage);

    if (this.$route.params.productKey == undefined || this.$route.params.productKey == "")
    {
        // This must be the 'event / message based route'
        console.log("Mounted without a productkey in the parameters, assuming event/message based implementation");
        window.addEventListener("message", this.receiveMessage);
    }
    else
    {
        console.log("Mounted with productkey '" + this.$route.params.productKey + "' in the parameters");
    }

      if (window.parent || window.opener) {
      this.getOpener().postMessage(this.createPostMsgObj("ViewerReady"), "*");
    }

    
    if (this.$route.params.productKey) {
      if (this.$route.params.productKey.startsWith("demo-")) {
        this.vwr.setDemoMode(true);
      }

      // Fetch data now and then load the object.
      Axios.get("/api/GetProductData/" + this.$route.params.productKey)
        .then((res) => {
          that.vwr.initialize(res.data.Products);
        })
        .catch((err) => {
          if (err.response.status == 404) {
            that.vwr.addError({
              hint: `No product with key '${that.$route.params.productKey}' could be found.`,
              message: "The key may be expired or invalid.",
            });
          }
        });
    }
  },
  beforeUnmount() {
    window.removeEventListener("message", this.receiveMessage);
      document.querySelector("body").classList.remove("no-overflow");
  },
};
</script>