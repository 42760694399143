<template>
  <button v-on:click="productViaApi()">Product via api</button>
  <button v-on:click="test()">Product Local</button>
  <hr />
  <iframe :src="frameUrl" ref="viewerFrame" class="viewerFrame"></iframe>
</template>

<script>
import axios from "axios";
import * as testdata from "../scripts/testdata.json";

export default {
  name: "ViewerCallTest",
  mounted() {},
  data() {
    return {
      frameUrl: "/Viewer3D",
      baseFrameUrl: "/Viewer3D",
    };
  },
  methods: {
    productViaApi() {
      // var frame = this.$refs.viewerFrame.contentWindow;
      var obj = { product: testdata.default.Product };

      axios.post("/Api/PostProduct", obj).then((res) => {
        var newUrl = this.baseFrameUrl + "/" + res.data.key;
        if (newUrl == this.frameUrl) {
          this.$refs.viewerFrame.contentWindow.location.reload();
        } else {
          this.frameUrl = newUrl;
        }
      });
    },
      test() {
      var frame = this.$refs.viewerFrame.contentWindow;
      var postObj = {
        type: "LoadViewer",
        data: testdata.default.Product,
      };

      frame.postMessage(postObj);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.viewerFrame {
  width: 75%;
  height: 75vh;
}
</style>
