import 'bootstrap/dist/css/bootstrap.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import hljs from "highlight.js";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faBorderNone, faCompressArrowsAlt, faRedo, faBug, faRedoAlt, faCog, faSpinner, faExclamationTriangle, faAngleDown, faAngleUp, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

library.add(faBorderNone, faCompressArrowsAlt, faRedo, faBug, faRedoAlt, faCog, faSpinner, faExclamationTriangle, faAngleDown, faAngleUp, faTimesCircle);

createApp(App).use(router).use(hljs.vuePlugin).mount('#app')
