export class ApiResponseObjectRoot {
    key!: number;
    data!: ApiResponseObjectData;
}

export class ApiResponseObjectData {
    model!: ApiResponseObjectModel;
    errors!: ApiResponseObjectError[];
    warnings!: string[];
    productPositions!: ApiResponseProductPosition[];
    product!: string;
}

export class ApiResponseObjectModel {
    objectFormat!: string;
    creationTime!: number;
    objFile!: ApiResponseObjectFile;
    mtlFile!: ApiResponseObjectFile;
}

export class ApiResponseObjectFile {
    fileName!: string;
    fileSizeKb!: number;
    downloadUrl!: string;
}

export class ApiResponseObjectError {
    message!: string;
    hint!: string;
}

export class ApiResponseProductPosition {
    offset!: Vector;
    level!: number;
    position!: number;
    positionSpan!: number;
}

export class Vector {
    x!: number;
    y!: number;
    z!: number;
}