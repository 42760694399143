<template>
  <div class="overlay">
    <div
      class="warningsAndErrors overlay-section"
      v-if="
        !Viewer3D.getDemoMode() &&
        Viewer3D.getWarnings().length + Viewer3D.getErrors().length + Viewer3D.getInfos().length > 0
      "
    >
        <div class="warningAndErrorContent" v-if="Viewer3D.getErrorLogEnabled()">
            <ul class="warnings">
                <li v-for="(w, i) in Viewer3D.getWarnings()" :key="i">
                    {{ w }}
                </li>
            </ul>
            <ul class="errors">
                <li v-for="(w, i) in Viewer3D.getErrors()" :key="i">
                    {{ w.hint }} - {{ w.message }}
                </li>
            </ul>
            <ul class="infos">
                <li v-for="(w, i) in Viewer3D.getInfos()" :key="i">
                    {{ w }}
                </li>
            </ul>
        </div>
        <div class="warningAndErrorHeader">
            <span :title="tooltips.error">
                <font-awesome-icon icon="times-circle" class="color-red" /> Errors:
                {{ Viewer3D.getErrors().length }}
            </span>
            <span :title="tooltips.warn">
                <font-awesome-icon icon="exclamation-triangle"
                                   class="color-orange" />
                Warnings: {{ Viewer3D.getWarnings().length }}
            </span>
            <span :title="tooltips.warn">
                Info: {{ Viewer3D.getInfos().length }}
            </span>
            <span v-on:click="Viewer3D.toggleErrorLog()">
                <font-awesome-icon icon="angle-down"
                                   style="font-size: 1.3em; cursor: pointer"
                                   v-if="!Viewer3D.getErrorLogEnabled()" />
                <font-awesome-icon icon="angle-up"
                                   style="font-size: 1.3em; cursor: pointer"
                                   v-if="Viewer3D.getErrorLogEnabled()" />
            </span>
        </div>
    </div>

    <div class="right overlay-section">
        <a v-on:click="Viewer3D.toggleGrid()"
           :class="{ active: Viewer3D.getGridEnabled() }">
            <font-awesome-icon icon="border-none" />
        </a>
        <br />
        <a v-on:click="Viewer3D.toggleAxes()"
           :class="{ active: Viewer3D.getAxesEnabled() }">
            <font-awesome-icon icon="compress-arrows-alt" />
        </a>
        <br />
        <a v-on:click="Viewer3D.resetCamera()" class="active">
            <font-awesome-icon icon="redo-alt" />
        </a>
        <br />
        <a v-on:click="toggleSettings()" :class="{ active: settingsEnabled }">
            <font-awesome-icon icon="cog" />
        </a>
    </div>
    <div class="settings" v-if="settingsEnabled && isMounted">
      <h3>Settings</h3>
      <hr />
      <table style="width: 100%">
        <thead>
          <tr>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <label>Object quality</label>
            </td>
            <td>
              <button
                v-on:click="changeAngularDeflection(0.8)"
                :class="{
                  'btn-primary': uncommittedSettings.angularDeflection == 0.8,
                  'btn-light': uncommittedSettings.angularDeflection != 0.8,
                  'btn btn-with-border': true,
                }"
              >
                low
              </button>
              <button
                v-on:click="changeAngularDeflection(0.5)"
                :class="{
                  'btn-primary': uncommittedSettings.angularDeflection == 0.5,
                  'btn-light': uncommittedSettings.angularDeflection != 0.5,
                  'btn btn-with-border': true,
                }"
              >
                medium
              </button>
              <button
                v-on:click="changeAngularDeflection(0.2)"
                :class="{
                  'btn-primary': uncommittedSettings.angularDeflection == 0.2,
                  'btn-light': uncommittedSettings.angularDeflection != 0.2,
                  'btn btn-with-border': true,
                }"
              >
                high
              </button>
              <button
                v-on:click="changeAngularDeflection(0)"
                :class="{
                  'btn-primary': uncommittedSettings.angularDeflection == 0,
                  'btn-light': uncommittedSettings.angularDeflection != 0,
                  'btn btn-with-border': true,
                }"
              >
                highest
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <hr />
      <button
        value="close"
        style="float: right"
        class="btn btn-primary btn-with-border"
        v-on:click="commitSettings()"
      >
        Save
      </button>

      <button
        value="close"
        style="float: right"
        class="btn btn-light btn-with-border"
        v-on:click="resetSettings()"
      >
        Close
      </button>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { Viewer3D } from "@/scripts/Viewer3D";

export default {
  components: { FontAwesomeIcon },
  name: "Home",
  data() {
    return {
      debug: false,
      debugInfo: null,
      settingsEnabled: false,
      linear_deflection: 1,
      isMounted: false,
      uncommittedSettings: {
        linearDeflection: 1,
        angularDeflection: 1,
      },
      warnsAndErrors: {
        active: false,
        warnings: [],
        errors: [],
      },
      tooltips: {
        error: "Errors prevent (parts of) the object from being generated.",
        warn: " Object can be generated but the data may not be correct.",
      },
    };
  },
  watch: {},
  props: {
    Viewer3D: Viewer3D,
  },
  mounted() {
    this.resetUncommittedChanges();
    this.isMounted = true;
  },
  beforeUnmount() {},
        methods: {
    toggleDebug() {
      this.debugInfo = this.Viewer3D.getDebugInfo();
      this.debug = !this.debug;
    },
    toggleSettings(value) {
      if (value != null) {
        this.settingsEnabled = value;
      } else {
        this.settingsEnabled = !this.settingsEnabled;
      }
    },
    commitSettings() {
      this.toggleSettings();

      var needsRedraw =
        this.uncommittedSettings.angularDeflection !=
        this.Viewer3D.getAngularDeflection();

      this.Viewer3D.setAngularDeflection(
        this.uncommittedSettings.angularDeflection
      );

      this.Viewer3D.setLinearDeflection(
        this.uncommittedSettings.linearDeflection
      );

      if (needsRedraw) {
        this.Viewer3D.reinitalize();
      }
    },
    resetSettings() {
      this.toggleSettings();
      this.resetUncommittedChanges();
    },
    resetUncommittedChanges() {
      this.uncommittedSettings.linearDeflection = this.Viewer3D.getLinearDeflection();
      this.uncommittedSettings.angularDeflection = this.Viewer3D.getAngularDeflection();
    },
    changeAngularDeflection(val, event) {
      console.log(event);
      this.uncommittedSettings.angularDeflection = val;
      this.uncommittedSettings.linearDeflection = val;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slider {
  width: 80%;
}

.btn-with-border {
  margin: 5px;
}

.settings table tr td:nth-child(1) {
  text-align: right;
}
.settings table tr td:nth-child(2) {
  text-align: left;
}

a {
  color: gray;
  background-color: transparent;
  font-size: 2em;
  cursor: pointer;
  display: block;
}

.left {
  left: 15px;
  text-align: left;
}
.right {
  right: 15px;
  text-align: right;
}

.overlay-section {
  width: calc(100% - 30px);
  pointer-events: none;
  position: absolute;
  top: 15px;
}

.overlay-section > * {
  pointer-events: all;
}

.overlay a {
  display: inline-block;
}

.active {
  color: black;
}

.overlay {
  width: 100%;
  position: relative;
  height: 100vh;
  pointer-events: none;
}

.settings {
  background-color: rgba(220, 220, 220, 0.8);
  border: solid #cecece 5px;
  border-radius: 15px;
  display: inline-block;
  position: relative;
  top: 50px;
  padding: 15px;
  width: 80%;
  height: auto;
  max-width: 600px;
  pointer-events: initial;
}

.settings > label {
  display: block;
}

.warningsAndErrors {
  top: unset;
  position: absolute;
  left: 0px;
  text-align: left;
  width: calc(100% - 60px);
  pointer-events: initial;
}

.warningAndErrorContent {
  overflow-y: scroll;
  background-color: rgba(200, 200, 200, 0.3);
  width: 100%;
  height: 25vh;
}

.warnings {
  color: orange;
  margin: 0px;
}

.errors {
  color: red;
  margin: 0px;
}

.hidden {
  display: none !important;
}

.badge {
  background-color: gray;
  border-radius: 9999px;
  font-size: 10px;
  position: absolute;
  right: 0px;
  bottom: 5px;
  color: white;
}

.badge_warn {
  background-color: orange;
}

.badge_error {
  background-color: red;
}

.warningAndErrorHeader > span {
  border: 1px solid grey;
  padding: 5px;
  background-color: rgba(200, 200, 200, 0.3);
  position: relative;
  top: 2px;
}
</style>
